import clsx from 'clsx';
import LogoDirecte from 'components/3cat/ui/LogoDirecte';
import __isEmpty from 'lodash/isEmpty';
import { canalDiccionari } from 'utils/canals';
import { getLogoDirecte } from './../Carrussel/canalHelper';
import styles from './directenodisponible.module.scss';

export default function DirecteNoDisponible(props) {
    const { className, estil, arafem } = props;
    const stylesText = clsx({
        [styles[estil]]: !__isEmpty(estil),
        [className]: !__isEmpty(className)
    });

    let textProp,
        textPropSubtitol,
        logo,
        src,
        alt,
        valor = new Array();

    valor = arafem;

    if (typeof valor.ara_fem !== 'undefined' || valor.id !== 'undefined') {
        return null;
    }

    if (
        typeof valor.ara_fem === 'undefined' &&
        (valor.name === 'c33' || valor.name === 'oca1' || valor.name === 'oca2' || valor.name === 'oca3' || valor.name === 'oca4')
    ) {
        textProp = 'Emissió no disponible';
        textPropSubtitol = canalDiccionari[valor.name].titol_programa;
        logo = canalDiccionari[valor.name].logo;
        src = getLogoDirecte(canalDiccionari[valor.name].logo);
        alt = `logo del canal ${canalDiccionari[valor.name].logo}`;
    }

    return (
        <div className={stylesText}>
            <LogoDirecte className={styles.logodirecte} canal={logo} logo={logo} />
            <div className={styles.titol}>{textProp}</div>
            <div className={styles.sotstitol}>{textPropSubtitol}</div>
        </div>
    );
}

DirecteNoDisponible.defaultProps = {
    className: null,
    estil: null
};
