import { genericPage, getServerSidePropsHelper } from 'components/3cat/Page';
import { useRouter } from 'next/router';
import { canalDiccionari } from 'utils/canals';

///directes/[id]/

const Pagina = (props) => {
    const router = useRouter();
    return genericPage(props, router, ['web', process.env.FOLDER_LAYOUTS, 'directes', 'canal']);
};

export async function getServerSideProps(context) {
    let { canal } = context.query;

    let path = ['web', process.env.FOLDER_LAYOUTS, 'directes', 'canal'];

    const re = /^fc[\d+]/;
    const re2 = /^pucfc[\d+]/;
    const re3 = /^pucoca[\d+]/;
    const re4 = /^beauties$/;
    if (canal.match(re) || canal.match(re2) || canal.match(re3) || canal.match(re4)) {
        path = ['web', process.env.FOLDER_LAYOUTS, 'directes', 'fastchannel'];
    } else {
        if (
            !Object.entries(canalDiccionari)
                .map(([key, canal]) => canal.slug)
                .includes(canal)
        ) {
            return {
                notFound: true
            };
        }
    }

    const options = {
        slugs: path,
        id: canal
    };

    return getServerSidePropsHelper(context, options);
}

export default Pagina;
